<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
<g clip-path="url(#clip0_13_49)">
<path d="M25 50C11.2149 50 0 38.7851 0 25C0 11.2149 11.2149 0 25 0C38.7851 0 50 11.2149 50 25C50 38.7851 38.7851 50 25 50Z" fill="#AD2AA5"/>
<path d="M25 44.043C14.4997 44.043 5.95703 35.5003 5.95703 25C5.95703 14.4997 14.4997 5.95703 25 5.95703C35.5003 5.95703 44.043 14.4997 44.043 25C44.043 35.5003 35.5003 44.043 25 44.043Z" fill="#E95DE2"/>
<path d="M33.1865 20.0002L28.1318 25L33.1865 29.9997C33.2779 29.8097 33.3333 29.5994 33.3333 29.375V20.625C33.3333 20.4005 33.2779 20.1903 33.1865 20.0002Z" fill="#A827A0"/>
<path d="M31.8685 19.1667H18.1315C17.906 19.1667 17.6949 19.2219 17.5039 19.3129L23.9643 25.7121C24.5356 26.2808 25.4644 26.2808 26.0357 25.7121L32.4961 19.3129C32.3051 19.2219 32.094 19.1667 31.8685 19.1667Z" fill="#A827A0"/>
<path d="M16.8133 20.0002C16.7219 20.1903 16.6665 20.4005 16.6665 20.625V29.375C16.6665 29.5995 16.7219 29.8097 16.8133 29.9997L21.868 25L16.8133 20.0002Z" fill="#A827A0"/>
<path d="M27.4414 25.6874L26.7262 26.3995C25.7743 27.3471 24.2256 27.3471 23.2738 26.3995L22.5586 25.6874L17.5039 30.6872C17.6949 30.7782 17.906 30.8334 18.1315 30.8334H31.8685C32.094 30.8334 32.3051 30.7782 32.4961 30.6872L27.4414 25.6874Z" fill="#A827A0"/>
</g>
<defs>
<clipPath id="clip0_13_49">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
</template>