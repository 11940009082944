import {createStore} from 'vuex'
import {getAccount,makeTransaction,checkConnect,switchChain,disconnect,callViewContractFunction,evmGetBalance } from 'kasatkin-crypto-core/src'
const stakeAbi = require('./stakeAbi.json')
const tokenAbi = require('./tokenAbi.json')
const {Web3} = require('web3');    

const tokenAddress = '0x28d6CEFDC7a57cB3E2958f61c95B20C76103Fd79'
const stakeAddress = '0x1fDE773A22a747865dCf93e367071b5221F226AB'
const rpc = 'https://ethereum.publicnode.com'
const wsRpc = 'wss://ethereum.publicnode.com'

const web3 = new Web3(wsRpc);
const stakeContract = new web3.eth.Contract(stakeAbi, stakeAddress);
const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
const tarifs = [3,6,12]

const tSwitchChain = async () => {
    const cc = await checkConnect({wallet:'MetaMask',rpc:''})
    if(cc?.chainId!==1) await switchChain({chainId: 1,wallet: 'MetaMask'});
    return cc
}
const loadData = async (acc,address,dispatch,commit) => {
    if(!acc) return;
    const _address = acc?.account?.[0]||acc?.accounts?.[0];
    commit('setAccount',acc)
    if(_address!==address){
        await dispatch('getAvailableSlopy')
        await dispatch('getWithdrawalAmount')
        await dispatch('getBalance')
    }
}

export default createStore({
    state: {
        user:{
            address: null,
            balance:{eth:0,slopy:0}
        },
        account:null,
        availableSlopy:0n,
        withdrawalAmount:0n,
        withdrawalLocked:0n,
        activeTarif:0,
        load:false,
        loadW:false,
    },
    getters: {
        getBalanse:(state)=>(key)=>parseInt(state.user.balance?.[key])/10**18||0,
        getWithdrawalAmount:(state)=>parseInt(state.withdrawalAmount)/10**18,
        setWithdrawalLocked:(state)=>parseInt(state.withdrawalLocked)/10**18,
        getAvailableSlopy:(state)=>parseInt(state.availableSlopy)/10**18,
        numberLocale:()=>(n,m=3)=>{
            const v = Number((n).toFixed(m)).toLocaleString('ru',{maximumFractionDigits:m}).replace(/,/g,'.');
            if(!parseFloat(v.replace(/\s/g,''))) return 0;
            return v
        },
    },
    mutations: {
        setActiveTarif(state,i){
            state.activeTarif = +i;
        },
        setAccount(state,account){
            state.account = account&&(account?.accounts?.length>0||account?.account?.length>0)?account:null;
            state.user.address = account?.accounts?.[0]||account?.account?.[0]||null;
        },
        setBalance(state,{balance,key}){
            state.user.balance[key] = balance
        },
        setLoad(state,l=false){
            state.load = l;
        },
        setLoadW(state,l=false){
            state.loadW = l;
        },
        walletDisconnect(state){
            state.account = null;
            state.user = {address:null,balance:{eth:0n,slopy:0n}}
            state.activeTarif = 0
        }, 
        setAvailableSlopy(state,n){
            state.availableSlopy = n
        },
        setWithdrawalAmount(state,n){
            state.withdrawalAmount = n
        },
        setWithdrawalLocked(state,n){
            state.withdrawalLocked = n
        }
    },
    actions: {
        async getWithdrawalAmount({state,commit}){
            if(!state.user.address) return;
            try {
                const arr = stakeAddress?(await callViewContractFunction({
                    from: state.user.address,
                    to: stakeAddress,
                    contractabi: stakeAbi,
                    function: 'getStakerData',
                    args: [],
                    rpc,
                })):[];
                let n = 0;
                let l = 0;
                if(arr?.length) n = arr.reduce((a,c)=>a+(+c[0]-Date.now()/1000<=0?(BigInt(c?.[1]||0))+(BigInt(c?.[2]||0)):0n),0n);
                if(arr?.length) l = arr.reduce((a,c)=>a+(BigInt(c?.[1]||0))+(BigInt(c?.[2]||0)),0n);
                commit('setWithdrawalAmount',n)
                commit('setWithdrawalLocked',l)
            } catch {
                return
            }
        },
        async getAvailableSlopy({state,commit}){
            if(!state.user.address||!tarifs?.[state.activeTarif]) return;
            try {
                const n = await callViewContractFunction({
                    from: state.user.address,
                    to: stakeAddress,
                    contractabi: stakeAbi,
                    function: 'getMaxAvail',
                    args: [tarifs[state.activeTarif]],
                    rpc
                });
                commit('setAvailableSlopy',n?BigInt(n):BigInt(n)||0n)
            } catch {
                return
            }
        },
        async checkConnectWallet({commit,dispatch}){
			const account = await tSwitchChain();
			commit('setAccount',account)
            await dispatch('getAvailableSlopy')
            await dispatch('getWithdrawalAmount')
		},
        async walletDisconnect({commit}){
            await disconnect({wallet: 'MetaMask'})
            commit('walletDisconnect')
        },
        async getBalance({state,commit}){
            if(!state.user.address) return;
            const [eth,slopy] = await Promise.all([
                evmGetBalance({from: state.user.address,rpc}),
                callViewContractFunction({
                    from: state.user.address,
                    to: tokenAddress,
                    contractabi: tokenAbi,
                    function: 'balanceOf',
                    args: [state.user.address],
                    rpc
                })
            ])
			commit('setBalance',{balance:eth?BigInt(eth):0,key:'eth'});
            commit('setBalance',{balance:slopy?BigInt(slopy):0,key:'slopy'});
        },
        async setAccount({commit,state,dispatch}){
            if(state.account) return;
            const data = {themeVariables:{'--w3m-z-index': 3001},wallet:'MetaMask'};
            try{
                const account = await getAccount(data);
                await tSwitchChain()
                await dispatch('getAvailableSlopy')
                await dispatch('getWithdrawalAmount')
                commit('setAccount',account)
                
            } catch{
                commit('setAccount',null)
            }
            await dispatch('getBalance')
        },
        async withdrawal({commit,state,dispatch}){
            if(state.loadW) return;
            commit('setLoadW',true)
            await tSwitchChain()
            let eventFuncW;
            const func = async (txHash, e)=>{
                if (e.detail.txHash === txHash) {
                    if(e.detail.status === 'error'||e.detail.status === 'succes'){
                        commit('setLoadW')
                        window.removeEventListener('SendTransaction',eventFuncW)
                        dispatch('getAvailableSlopy')
                        dispatch('getWithdrawalAmount')
                    }
                }
			}
			const txHash = await makeTransaction({
                wallet: 'MetaMask',
                from: state.user.address,
                to: stakeAddress,
                contractabi: stakeAbi,
                function: 'claim',
                args:[]
            })
            if (txHash) {
                eventFuncW = func.bind(null, txHash)
                window.addEventListener('SendTransaction',eventFuncW)
            } else {
				commit('setLoadW')
			}
        },
        async stake({commit,state,dispatch},amount){
            if(state.load||!tarifs?.[state.activeTarif]) return;
            commit('setLoad',true)
            await tSwitchChain()
            let eventFunc;

            if (!amount.toString().replace(/,/g,'.').includes('.')) {
                amount = amount.toString().replace(/,/g,'.').split('.')[0] + '0'.repeat(18)
            } else {
                if (amount.toString().replace(/,/g,'.').split('.')[1].length<18) {
                    amount = amount.toString().replace(/,/g,'.').split('.')[0] + amount.toString().replace(/,/g,'.').split('.')[1] + '0'.repeat(18-amount.toString().replace(/,/g,'.').split('.')[1].length)
                } else {
                    amount = amount.toString().replace(/,/g,'.').split('.')[0] + amount.toString().replace(/,/g,'.').split('.')[1].slice(0,18)
                }
            }
            const data = await callViewContractFunction({
                from: state.user.address,
                to: tokenAddress,
                contractabi: tokenAbi,
                function: 'allowance',
                args: [state.user.address,stakeAddress],
                rpc
            })
            const tx = {
                wallet: 'MetaMask',
                from: state.user.address,
                to: stakeAddress,
                contractabi: stakeAbi,
                function: 'stake',
                args: [
                    amount,
                    tarifs[state.activeTarif]
                ]
            }
            const sendTrns = async () => {
                const txHash = await makeTransaction(tx)
                if(txHash) {
                    eventFunc = funcStake.bind(null, txHash);
                    window.addEventListener('SendTransaction',eventFunc)
                } else  {
                    commit('setLoad')
                }
            }
            const funcStake = async (txHash, e)=>{
                if (e.detail.txHash === txHash) {
                    if(e.detail.status === 'error'||e.detail.status === 'succes'){
                        commit('setLoad')
                        window.removeEventListener('SendTransaction',eventFunc)
                        dispatch('getAvailableSlopy')
                        dispatch('getWithdrawalAmount')
                    }
                }
			}
            const func = async (txHash, e)=>{
                if (e.detail.txHash === txHash) {
                    if(e.detail.status === 'error') {
                        window.removeEventListener('SendTransaction',eventFunc)
                        commit('setLoad')
                    } else if (e.detail.status === 'succes'){
                        window.removeEventListener('SendTransaction',eventFunc)
                        await sendTrns();
                    }
                }
			}
            if (BigInt(data) < BigInt(amount)) {
                const txHash = await makeTransaction({
                    wallet: 'MetaMask',
                    from: state.user.address,
                    to: tokenAddress,
                    contractabi: tokenAbi,
                    function: 'approve',
                    args: [
                        stakeAddress,
                        amount
                    ]
                })
                if (txHash) {
                    eventFunc = func.bind(null, txHash);
                    window.addEventListener('SendTransaction',eventFunc)
                } else {
                    commit('setLoad')
                }
            }
            else {
                await sendTrns();
            }
        },
        newWalletConnected({commit,state,dispatch}){
            window.addEventListener('NewWalletConnected',({detail})=>{
                loadData(detail,state.user.address,dispatch,commit)
            })
        },
        walletAccountsChanged({commit,state,dispatch}){
            window.addEventListener('WalletAccountsChanged',({detail})=>{
                loadData(detail,state.user.address,dispatch,commit)
            })
        },
        walletDisconnected({commit}){
            window.addEventListener('WalletDisconnected',()=>{
                commit('walletDisconnect')
            })
        },
        sendTransaction({state,commit}){
            window.addEventListener('SendTransaction',async ()=>{
                const b = await evmGetBalance({from: state.user.address,rpc})
			    commit('setBalance',{balance:b?BigInt(b):0n,key:'eth'});
            })
        },
        onWeb3({dispatch,state}){
            tokenContract.events.Transfer().on('data', (e) => {
                dispatch('getWithdrawalAmount')
                dispatch('getAvailableSlopy')
                if(state.user.address&&(state.user.address === e.to||state.user.address === e.from)) dispatch('getBalance');
            })
            stakeContract.events.TokensStaked().on('data', (e) => {
                dispatch('getAvailableSlopy')
                if(e.user === state.user.address) dispatch('getBalance');
            })
            stakeContract.events.TokensUnstaked().on('data', (e) => {
                dispatch('getAvailableSlopy')
                if(e.user === state.user.address) {
                    dispatch('getWithdrawalAmount')
                    dispatch('getBalance')
                }
            })
        }
    }
})
