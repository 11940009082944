<template>
    <n-config-provider :theme-overrides="themeOverrides" :breakpoints="{xs:0,s:576,m:768,l:992,xl:1200,xxl:1500}">
        <!-- <NThemeEditor> -->
        <div class="home">
            <header-vue/>
            <div class="container">   
                <router-view :key="$route.fullPath"></router-view>
            </div>
            <footer-vue/>
        </div>   
        <!-- </NThemeEditor> -->
    </n-config-provider>
</template>
<script setup>
import {onMounted} from 'vue';
import {NThemeEditor,NConfigProvider} from 'naive-ui'
import headerVue from './components/header.vue';
import footerVue from './components/footer.vue';
import {useStore} from 'vuex';

const store = useStore()
onMounted(async ()=>{
    store.dispatch('newWalletConnected')
    store.dispatch('walletAccountsChanged')
    store.dispatch('walletDisconnected')
    store.dispatch('onWeb3')
	await store.dispatch('checkConnectWallet')
})

const themeOverrides = {
	common:{
		primaryColor: '#961C97',
		primaryColorHover: '#961C97',
        primaryColorPressed: '#961C97'
	},
    Button:{
        colorPrimary:'#fff',
        textColorPrimary: '#961C97',
        textColorHoverPrimary: '#961C97',
        textColorPressedPrimary: '#961C97',
        textColorFocusPrimary: '#961C97',
        colorPressedPrimary: '#fff',
        colorFocusPrimary: '#fff',
        colorHoverPrimary:'#d3d3d3',
        opacityDisabled: 0.6,
        colorDisabledPrimary:'#d3d3d3',
        textColorDisabledPrimary: 'ffffff'
    }
}
</script>
<style lang="scss">
.home{
	display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content auto min-content;
	min-height: 100vh;
}
</style>
