<template>
    <div class="s-menu lilita-one">
        <div class="s-menu-item" v-for="m,i in menu" :class="{active:route.name === m.key}" :key="i">
            <a v-if="m.link.includes('http')" :target="m.blank?'_blank':undefined" :href="m.link">{{m.label}}</a>
            <router-link v-else :to="m.link">{{m.label}}</router-link>
        </div>
    </div>
</template>
<script setup>
import {useRoute} from 'vue-router';

const route = useRoute()
const menu = [
    {label:'Home',key:'home',link:'https://slopy.io/'},
    {label:'Docs',key:'docs',link:'https://docs.slopy.io/',blank:true},
    {label:'Stake',key:'stake',link:'/'},
]
</script>
<style scoped lang="scss">
.s-menu{
    display: flex;
    gap: 55px;
    margin-right: 80px;
    margin-top: 15px;
    .s-menu-item{
        font-size: 22px;
        color: #fff;
        padding: 0 7px;
        position: relative;
        &:hover::before,&.active::before{
            width: calc(100% + 7px);
        }
        &::before{
            content: '';
            position: absolute;
            bottom: -7px;
            width: 0;
            height: 2px;
            left: 50%;
            background-color: #fff;
            transform: translateX(-50%);
            transition: width .3s ease-in-out;
        }
    }
}
@media(max-width:900px) {
    .s-menu{gap: 25px;margin-right: 25px;}
}
@media(max-width:768px) {
    .s-menu{gap: 20px;margin-right: 30px;}
    .s-menu .s-menu-item{font-size: 18px;}
}
</style>