<template>
    <header class="lilita-one">
        <div class="logo">
            <a href="https://slopy.io/"><img src="@/assets/img/logo.svg"></a>
            <a href="https://slopy.io/"><p>Slopy</p></a>
        </div>
        <s-menu class="d-md-none"/>
        <a href="https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x28d6CEFDC7a57cB3E2958f61c95B20C76103Fd79">
            <n-button type="primary" class="d-md-none" style="font-size: 18px;height: 49px;padding: 0 46px;margin-top: 15px;width: 181px;" round :bordered="false">TRADE NOW</n-button>
        </a>
        <div class="menu-btn" :class="{active:show}" @click="toShow()"><div v-for="i in 2" :key="i"></div></div>
        <Transition>
            <div class="menu-container" v-show="show">
                <s-menu/>
                <n-button type="primary" class="m-btn" style="font-size: 18px;height: 42px;padding: 0 33px;" round>Trade now</n-button>
            </div>
        </Transition>
    </header>
</template>
<script setup>
import {ref,watch} from 'vue';
import {useRoute} from 'vue-router';
import {NButton} from 'naive-ui';
import sMenu from './sMenu.vue';

const route = useRoute()
const show = ref(false)

const toShow = (f) => {
    show.value = f?false:!show.value;
    document.documentElement.style.overflow = document.body.style.overflow = show.value?'hidden':'';
}
watch(()=>route.fullPath,()=>{
    toShow(true)
})
</script>
<style scoped lang="scss">
.v-enter-active,.v-leave-active {transition: all 0.4s ease;}
.v-enter-from,.v-leave-to {transform: translateY(100%);}
header{
    height: 89px;
    padding-top: 24px;
}
.menu-btn{
    z-index: 100;
    height: 16px;
    width: 25px;
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 20px;
    display: none;
    div{
        position: absolute;
        right: 0;
        top: 2px;
        height: 3px;
        width: 70%;
        background-color: #fff;
        transition-duration: .2s;
        border-radius: 3px;
    }
    div+div{
        top: 10px;
        width: 100%;
    }
    &.active{
        div:first-child{
            top: 4px;
            transform: translateY(4px) rotate(45deg);
            width: 100%;
        }
        div:last-child {
            top: 14px;
            transform: translateY(-6px) rotate(-45deg);
        }
    }
}
.menu-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99;
    display: none;
    background-color: #961C97;
}
.logo{z-index: 100;}
@media(max-width:768px) {
    .menu-btn,.menu-container{display: block;}
    header{padding-top: 0;}
    header .menu-container{
        .m-btn{
            margin-left: 50%;
            margin-top: 30px;
            transform: translateX(-50%);
        }
        :deep(.s-menu){
            margin-right: 0;
            flex-direction: column;
            align-items: center;
            margin-top: 150px;
            gap: 50px;
            .s-menu-item{
                width: max-content;
                font-size: 20px;
            }
        }
    }
}
@media(max-width:576px) {
    header .menu-container :deep(.s-menu){gap: 30px;}
}
</style>