import {createRouter,createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'stake',
        component: () => import('@/views/Stake.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
		return {top: 0}
	},
    routes
})

export default router
